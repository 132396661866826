* {
    padding: 0;
    margin: 0;
    box-sizing: border-box; 
}

html, body {
    max-height: 100%;
    font-family: 'Open Sans', sans-serif;
}

button {
    font-family: 'Open Sans', sans-serif;
}

.container {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
}

#root{
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.App {
    height: 100%;
    background: #EDEDED;
    display: flex;
    flex-direction: column;
}


.main {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
}

.container__flex{
    display: flex;
    justify-content: space-between;
}

.add-friends {
    z-index: 1;
    position: absolute;
    right: 1%;
    bottom: 100px;
}