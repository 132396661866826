:root {
    --main-color: #1D4C89;
    --main-color-hover: #5e86bb;
}
.btn {
    position: relative;
    display: inline-block;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    padding: 12px 16px;
    border-radius: 33px;
    cursor: pointer;
    transition: all 0.5s;
}

.btn > * {
    position: relative;
    display: block;
}

.btn__color_main {
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
}

.btn__color_main:hover {
    background-color: var(--main-color-hover);
    border: 1px solid var(--main-color-hover);
}

.btn__color_main:active {
    background-color: var(--main-color);
    border: 1px solid var(--main-color); 
}

.btn__icon{
    padding-left: 30px;
}

.btn__icon:before {
   
    width: 20px;
    height: 20px;
    display: block;
    content: '';
    position: absolute;
    left:0;
    top:50%;
    transform: translate(0, -50%);
}

.btn__icon_add:before {
    background: url(../../../public/link.svg) no-repeat;

}