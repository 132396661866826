.app-header {
    padding: 21px 0 25px 0;
    background: #E7EAEF;
    border-bottom: 3px solid #D0D8E2;
    box-shadow: 0px 4px 12px rgba(110, 115, 121, 0.21);
}

.app-header__line{
    height: 40px;
    display: flex;
    align-items: center;
}