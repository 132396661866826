.modal {
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10;
    top:0;
    left:0;
    transition: all 0.3s;
}

.modal__overlay_active{
    /* background-color: rgba(0,0,0, 0.8); */
    backdrop-filter: blur(3px);
}

.modal__inner {

    transition: all 0.5s;
    padding: 35px 52px;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: -100%;
    width: 100%;
    max-width: 500px;
    height: auto;
    transform: translate(-50%, -50%); 
    background: #FBFBFB;
    border: 1px solid #D3DAE4;
    box-shadow: 0px 11px 14px rgba(189, 189, 189, 0.54);
}

.modal__overlay_active .modal__inner{
    top: 20%;   
}